
import Vue from "vue";
import { Post } from "@/types/interfaces";
import ShareDropdown from "@/components/ShareDropdown.vue";
import { mapGetters } from "vuex";
import DeletePostModal from "@/components/Modals/DeletePostModal.vue";

export default Vue.extend({
  name: "Post",
  components: {
    DeletePostModal,
    ShareDropdown
  },
  props: {
    post: {
      type: Object as () => Post
    },
    isRecentActivity: Boolean,
    text: String,
    date: String,
    post_id: Number,
    index: {
      type: Number
    }
  },
  data() {
    return {
      // Reactions buttons
      buttons: [
        {
          name: (this as any).$t("post.postButtonsAgree"),
          icon: "clap"
        },
        {
          name: (this as any).$t("post.postButtonsSceptic"),
          icon: "sceptic"
        },
        {
          name: (this as any).$t("post.postButtonsDisAgree"),
          icon: "disagree"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted(): void {
    // console.log(this.post)
    // console.log('index',this.index)
  },
  methods: {
    formatDesc(text: string): string {
      const urlPattern = /https?:\/\/\S+|www\.\S+/g;

      const replacedText = text.replace(urlPattern, match => {
        return `<a href="${match}" target="_blank" style="color: #4E5D78;text-decoration: underline">${match}</a>`;
      });
      return replacedText;
    },
    getPostUrl(post: any) {
      return `${process.env.VUE_APP_HOST_URL}/posts/${post.id}`;
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    refreshPosts() {
      this.$emit("refreshPosts");
    },
    likeable(type: string, id: string, index: string, post_id: string) {
      this.$store
        .dispatch("likeable/SET_LIKE", {
          likeType: "post",
          id: id,
          data: {
            type,
            like: true
          }
        })
        .then(data => {
          console.log(post_id, data.like_counts);
          this.$emit("updateLike", post_id, data.like_counts);
        });
    },
    singlePost() {
      this.$router.push({
        path: `/posts/${this.post.id}`
      });
    },
    share() {
      console.log("Share button was clicked!");
    },
    comment() {
      this.$router.push({
        path: `/posts/${this.post.id}`
      });
    },
    openDeletePostModal() {
      console.log("profile -> ", this.profile.id, this.post);
      this.$bvModal.show("delete-post-modal");
    }
  }
});
