
import Vue from "vue";

export default Vue.extend({
  name: "DeletePostModal",
  props: {
    post: Object,
    route: Object
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    onDeletePost() {
      this.isLoading = true;
      this.$store
        .dispatch("post/REMOVE_POST", this.post.id)
        .then(data => {
          this.isLoading = false;
          console.log("remove post", data, this.route);
          (this as any).$alertify.success(data.message);
          this.$emit("refreshPosts");
          this.closeModal();
          if (this.route.name == "PostDetailsPage") this.$router.back();
        })
        .catch(error => {
          this.isLoading = false;
          if (error.response.status === 422) {
            (this as any).$alertify.error(error.response.data.errors[0]);
          }
        });
    },
    closeModal() {
      this.$bvModal.hide("delete-post-modal");
    }
  }
});
